var lightPlus = Object.freeze({
  "colors": {
    "actionBar.toggledBackground": "#dddddd",
    "activityBarBadge.background": "#007ACC",
    "checkbox.border": "#919191",
    "editor.background": "#FFFFFF",
    "editor.foreground": "#000000",
    "editor.inactiveSelectionBackground": "#E5EBF1",
    "editor.selectionHighlightBackground": "#ADD6FF80",
    "editorIndentGuide.activeBackground": "#939393",
    "editorIndentGuide.background": "#D3D3D3",
    "editorSuggestWidget.background": "#F3F3F3",
    "input.placeholderForeground": "#767676",
    "list.activeSelectionIconForeground": "#FFF",
    "list.focusAndSelectionOutline": "#90C2F9",
    "list.hoverBackground": "#E8E8E8",
    "menu.border": "#D4D4D4",
    "notebook.cellBorderColor": "#E8E8E8",
    "notebook.selectedCellBackground": "#c8ddf150",
    "ports.iconRunningProcessForeground": "#369432",
    "searchEditor.textInputBorder": "#CECECE",
    "settings.numberInputBorder": "#CECECE",
    "settings.textInputBorder": "#CECECE",
    "sideBarSectionHeader.background": "#0000",
    "sideBarSectionHeader.border": "#61616130",
    "sideBarTitle.foreground": "#6F6F6F",
    "statusBarItem.errorBackground": "#c72e0f",
    "statusBarItem.remoteBackground": "#16825D",
    "statusBarItem.remoteForeground": "#FFF",
    "tab.lastPinnedBorder": "#61616130",
    "terminal.inactiveSelectionBackground": "#E5EBF1",
    "widget.border": "#d4d4d4"
  },
  "displayName": "Light Plus",
  "name": "light-plus",
  "semanticHighlighting": true,
  "semanticTokenColors": {
    "customLiteral": "#795E26",
    "newOperator": "#AF00DB",
    "numberLiteral": "#098658",
    "stringLiteral": "#a31515"
  },
  "tokenColors": [
    {
      "scope": [
        "meta.embedded",
        "source.groovy.embedded",
        "string meta.image.inline.markdown",
        "variable.legacy.builtin.python"
      ],
      "settings": {
        "foreground": "#000000ff"
      }
    },
    {
      "scope": "emphasis",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "strong",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "meta.diff.header",
      "settings": {
        "foreground": "#000080"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "foreground": "#008000"
      }
    },
    {
      "scope": "constant.language",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": [
        "constant.numeric",
        "variable.other.enummember",
        "keyword.operator.plus.exponent",
        "keyword.operator.minus.exponent"
      ],
      "settings": {
        "foreground": "#098658"
      }
    },
    {
      "scope": "constant.regexp",
      "settings": {
        "foreground": "#811f3f"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": "entity.name.selector",
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "foreground": "#e50000"
      }
    },
    {
      "scope": [
        "entity.other.attribute-name.class.css",
        "entity.other.attribute-name.class.mixin.css",
        "entity.other.attribute-name.id.css",
        "entity.other.attribute-name.parent-selector.css",
        "entity.other.attribute-name.pseudo-class.css",
        "entity.other.attribute-name.pseudo-element.css",
        "source.css.less entity.other.attribute-name.id",
        "entity.other.attribute-name.scss"
      ],
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": "invalid",
      "settings": {
        "foreground": "#cd3131"
      }
    },
    {
      "scope": "markup.underline",
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#000080"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#800000"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "markup.strikethrough",
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inserted",
      "settings": {
        "foreground": "#098658"
      }
    },
    {
      "scope": "markup.deleted",
      "settings": {
        "foreground": "#a31515"
      }
    },
    {
      "scope": "markup.changed",
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": [
        "punctuation.definition.quote.begin.markdown",
        "punctuation.definition.list.begin.markdown"
      ],
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": "punctuation.definition.tag",
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": [
        "meta.preprocessor",
        "entity.name.function.preprocessor"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "meta.preprocessor.string",
      "settings": {
        "foreground": "#a31515"
      }
    },
    {
      "scope": "meta.preprocessor.numeric",
      "settings": {
        "foreground": "#098658"
      }
    },
    {
      "scope": "meta.structure.dictionary.key.python",
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "storage.type",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": [
        "storage.modifier",
        "keyword.operator.noexcept"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": [
        "string",
        "meta.embedded.assembly"
      ],
      "settings": {
        "foreground": "#a31515"
      }
    },
    {
      "scope": [
        "string.comment.buffered.block.pug",
        "string.quoted.pug",
        "string.interpolated.pug",
        "string.unquoted.plain.in.yaml",
        "string.unquoted.plain.out.yaml",
        "string.unquoted.block.yaml",
        "string.quoted.single.yaml",
        "string.quoted.double.xml",
        "string.quoted.single.xml",
        "string.unquoted.cdata.xml",
        "string.quoted.double.html",
        "string.quoted.single.html",
        "string.unquoted.html",
        "string.quoted.single.handlebars",
        "string.quoted.double.handlebars"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "string.regexp",
      "settings": {
        "foreground": "#811f3f"
      }
    },
    {
      "scope": [
        "punctuation.definition.template-expression.begin",
        "punctuation.definition.template-expression.end",
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": [
        "meta.template.expression"
      ],
      "settings": {
        "foreground": "#000000"
      }
    },
    {
      "scope": [
        "support.constant.property-value",
        "support.constant.font-name",
        "support.constant.media-type",
        "support.constant.media",
        "constant.other.color.rgb-value",
        "constant.other.rgb-value",
        "support.constant.color"
      ],
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": [
        "support.type.vendored.property-name",
        "support.type.property-name",
        "variable.css",
        "variable.scss",
        "variable.other.less",
        "source.coffee.embedded"
      ],
      "settings": {
        "foreground": "#e50000"
      }
    },
    {
      "scope": [
        "support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "keyword.control",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "keyword.operator",
      "settings": {
        "foreground": "#000000"
      }
    },
    {
      "scope": [
        "keyword.operator.new",
        "keyword.operator.expression",
        "keyword.operator.cast",
        "keyword.operator.sizeof",
        "keyword.operator.alignof",
        "keyword.operator.typeid",
        "keyword.operator.alignas",
        "keyword.operator.instanceof",
        "keyword.operator.logical.python",
        "keyword.operator.wordlike"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "keyword.other.unit",
      "settings": {
        "foreground": "#098658"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded.begin.php",
        "punctuation.section.embedded.end.php"
      ],
      "settings": {
        "foreground": "#800000"
      }
    },
    {
      "scope": "support.function.git-rebase",
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": "constant.sha.git-rebase",
      "settings": {
        "foreground": "#098658"
      }
    },
    {
      "scope": [
        "storage.modifier.import.java",
        "variable.language.wildcard.java",
        "storage.modifier.package.java"
      ],
      "settings": {
        "foreground": "#000000"
      }
    },
    {
      "scope": "variable.language",
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": [
        "entity.name.function",
        "support.function",
        "support.constant.handlebars",
        "source.powershell variable.other.member",
        "entity.name.operator.custom-literal"
      ],
      "settings": {
        "foreground": "#795E26"
      }
    },
    {
      "scope": [
        "support.class",
        "support.type",
        "entity.name.type",
        "entity.name.namespace",
        "entity.other.attribute",
        "entity.name.scope-resolution",
        "entity.name.class",
        "storage.type.numeric.go",
        "storage.type.byte.go",
        "storage.type.boolean.go",
        "storage.type.string.go",
        "storage.type.uintptr.go",
        "storage.type.error.go",
        "storage.type.rune.go",
        "storage.type.cs",
        "storage.type.generic.cs",
        "storage.type.modifier.cs",
        "storage.type.variable.cs",
        "storage.type.annotation.java",
        "storage.type.generic.java",
        "storage.type.java",
        "storage.type.object.array.java",
        "storage.type.primitive.array.java",
        "storage.type.primitive.java",
        "storage.type.token.java",
        "storage.type.groovy",
        "storage.type.annotation.groovy",
        "storage.type.parameters.groovy",
        "storage.type.generic.groovy",
        "storage.type.object.array.groovy",
        "storage.type.primitive.array.groovy",
        "storage.type.primitive.groovy"
      ],
      "settings": {
        "foreground": "#267f99"
      }
    },
    {
      "scope": [
        "meta.type.cast.expr",
        "meta.type.new.expr",
        "support.constant.math",
        "support.constant.dom",
        "support.constant.json",
        "entity.other.inherited-class"
      ],
      "settings": {
        "foreground": "#267f99"
      }
    },
    {
      "scope": [
        "keyword.control",
        "source.cpp keyword.operator.new",
        "source.cpp keyword.operator.delete",
        "keyword.other.using",
        "keyword.other.directive.using",
        "keyword.other.operator",
        "entity.name.operator"
      ],
      "settings": {
        "foreground": "#AF00DB"
      }
    },
    {
      "scope": [
        "variable",
        "meta.definition.variable.name",
        "support.variable",
        "entity.name.variable",
        "constant.other.placeholder"
      ],
      "settings": {
        "foreground": "#001080"
      }
    },
    {
      "scope": [
        "variable.other.constant",
        "variable.other.enummember"
      ],
      "settings": {
        "foreground": "#0070C1"
      }
    },
    {
      "scope": [
        "meta.object-literal.key"
      ],
      "settings": {
        "foreground": "#001080"
      }
    },
    {
      "scope": [
        "support.constant.property-value",
        "support.constant.font-name",
        "support.constant.media-type",
        "support.constant.media",
        "constant.other.color.rgb-value",
        "constant.other.rgb-value",
        "support.constant.color"
      ],
      "settings": {
        "foreground": "#0451a5"
      }
    },
    {
      "scope": [
        "punctuation.definition.group.regexp",
        "punctuation.definition.group.assertion.regexp",
        "punctuation.definition.character-class.regexp",
        "punctuation.character.set.begin.regexp",
        "punctuation.character.set.end.regexp",
        "keyword.operator.negation.regexp",
        "support.other.parenthesis.regexp"
      ],
      "settings": {
        "foreground": "#d16969"
      }
    },
    {
      "scope": [
        "constant.character.character-class.regexp",
        "constant.other.character-class.set.regexp",
        "constant.other.character-class.regexp",
        "constant.character.set.regexp"
      ],
      "settings": {
        "foreground": "#811f3f"
      }
    },
    {
      "scope": "keyword.operator.quantifier.regexp",
      "settings": {
        "foreground": "#000000"
      }
    },
    {
      "scope": [
        "keyword.operator.or.regexp",
        "keyword.control.anchor.regexp"
      ],
      "settings": {
        "foreground": "#EE0000"
      }
    },
    {
      "scope": [
        "constant.character",
        "constant.other.option"
      ],
      "settings": {
        "foreground": "#0000ff"
      }
    },
    {
      "scope": "constant.character.escape",
      "settings": {
        "foreground": "#EE0000"
      }
    },
    {
      "scope": "entity.name.label",
      "settings": {
        "foreground": "#000000"
      }
    }
  ],
  "type": "light"
});

export { lightPlus as default };
